import {
  HomeOutlined,
  ProjectOutlined,
  AppstoreAddOutlined,
  GoldOutlined,
  WechatOutlined,
  // DashboardOutlined,
  GroupOutlined,
  TeamOutlined,
  RiseOutlined,
  ApartmentOutlined,
  SmileOutlined,
  BankOutlined,
  StarOutlined,
  CheckSquareOutlined,
  SketchOutlined,
  FileImageOutlined,
  UnorderedListOutlined,
  MailOutlined,
  CopyOutlined,
  SettingOutlined,
  PhoneOutlined,
  MinusOutlined
} from '@ant-design/icons';
import type { MenuProps } from 'antd';

type MenuItem = Required<MenuProps>['items'][number];

function getItem(label: React.ReactNode, key: React.Key, icon?: React.ReactNode, children?: MenuItem[]): MenuItem {
  return {
    key,
    icon,
    children,
    label
  } as MenuItem;
}

export const useRouteTranslation = () => {
  const items: MenuItem[] = [
    // getItem('Dashboard', AppRoute.dashboard, <DashboardOutlined />),
    getItem('Trang chủ', AppRoute.homeGroup, <HomeOutlined />, [
      getItem('Giới thiệu', AppRoute.homeIntro, <GoldOutlined />),
      getItem('Thành quả', AppRoute.homeOperationalResults, <SketchOutlined />),
      getItem('Quy trình', AppRoute.homeDesignProcess, <CheckSquareOutlined />),
      getItem('Xưởng', AppRoute.homeIntroFactory, <BankOutlined />),
      getItem('Xu hướng thiết kế', AppRoute.homeTrendDesign, <StarOutlined />)
    ]),

    getItem('Giới thiệu', AppRoute.aboutGroup, <SmileOutlined />, [
      getItem('Dịch vụ cung cấp', AppRoute.aboutService, <ApartmentOutlined />),
      getItem('Tầm nhìn giá trị', AppRoute.aboutVision, <RiseOutlined />),
      getItem('Đội ngũ', AppRoute.aboutTeam, <TeamOutlined />),
      getItem('Giá trị mang lại', AppRoute.aboutValue, <GroupOutlined />)
    ]),

    getItem('SDecor', AppRoute.sDecorGroup, <ProjectOutlined />, [
      getItem('Thông tin thêm', AppRoute.sDecorMeta, <GroupOutlined />),
      getItem('Danh sách', AppRoute.sDecor, <UnorderedListOutlined />),
      getItem('Thêm', AppRoute.sDecorCreate, <AppstoreAddOutlined />),
      getItem('Loại dự án', AppRoute.sDecorType, <GoldOutlined />)
    ]),

    getItem('SHome', AppRoute.sHomeGroup, <ProjectOutlined />, [
      getItem('Thông tin thêm', AppRoute.sHomeMeta, <GroupOutlined />),
      getItem('Danh sách', AppRoute.sHome, <UnorderedListOutlined />),
      getItem('Thêm', AppRoute.sHomeCreate, <AppstoreAddOutlined />),
      getItem('Loại dự án', AppRoute.sHomeType, <GoldOutlined />)
    ]),

    getItem('SFuni', AppRoute.sFuniGroup, <ProjectOutlined />, [
      getItem('Thông tin thêm', AppRoute.sFuniMeta, <GroupOutlined />),
      getItem('Danh sách', AppRoute.sFuni, <UnorderedListOutlined />),
      getItem('Thêm', AppRoute.sFuniCreate, <AppstoreAddOutlined />),
      getItem('Loại dự án', AppRoute.sFuniType, <GoldOutlined />)
    ]),

    getItem('SGarden', AppRoute.sGardenGroup, <ProjectOutlined />, [
      getItem('Thông tin thêm', AppRoute.sGardenMeta, <GroupOutlined />),
      getItem('Danh sách', AppRoute.sGarden, <UnorderedListOutlined />),
      getItem('Thêm', AppRoute.sGardenCreate, <AppstoreAddOutlined />),
      getItem('Loại dự án', AppRoute.sGardenType, <GoldOutlined />)
    ]),

    getItem('Blog', AppRoute.blogGroup, <CopyOutlined />, [
      getItem('Danh sách', AppRoute.blog, <UnorderedListOutlined />),
      getItem('Thêm', AppRoute.blogCreate, <AppstoreAddOutlined />),
      getItem('Loại blog', AppRoute.blogType, <GoldOutlined />)
    ]),

    getItem('Tư vấn', AppRoute.adviseGroup, <WechatOutlined />, [
      getItem('Danh sách', AppRoute.advise, <UnorderedListOutlined />),
      getItem('Thêm', AppRoute.adviseCreate, <AppstoreAddOutlined />),
      getItem('Loại tư vấn', AppRoute.adviseType, <GoldOutlined />)
    ]),

    getItem('Báo giá', AppRoute.quote, <MailOutlined />),
    getItem('Banner', AppRoute.banner, <FileImageOutlined />),
    getItem('Cài đặt', AppRoute.settingGroup, <SettingOutlined />, [
      getItem('Liên hệ', AppRoute.settingContact, <PhoneOutlined />),
      getItem('Meta', AppRoute.settingMeta, <MinusOutlined />)
    ])
  ];

  return items;
};

export const AppRoute = {
  all: '*',

  basic: '/',
  dashboard: '/dashboard',
  login: '/dang-nhap',
  quote: '/quote',

  homeGroup: '/trang-chu',
  homeIntro: '/trang-chu/gioi-thieu',
  homeOperationalResults: '/trang-chu/thanh-qua-hoat-dong',
  homeDesignProcess: '/trang-chu/quy-trinh-thiet-ke-thi-cong',
  homeIntroFactory: '/trang-chu/xuong-thiet-ke',
  homeTrendDesign: '/trang-chu/xu-huong-thiet-ke',

  aboutGroup: '/gioi-thieu',
  aboutService: '/gioi-thieu/dich-vu-cung-cap',
  aboutVision: '/gioi-thieu/tam-nhin-gia-tri',
  aboutTeam: '/gioi-thieu/doi-ngu',
  aboutValue: '/gioi-thieu/gia-tri-mang-lai',

  /**
   * S Decor
   */
  sDecorGroup: '/s-decor',
  sDecor: '/s-decor',
  sDecorMeta: '/s-decor-meta',
  sDecorCreate: '/s-decor/create',
  sDecorUpdate: '/s-decor/edit/:id',
  sDecorUpdateId: (id: string) => {
    return `/s-decor/edit/${id}`;
  },
  sDecorDetail: '/s-decor/detail/:id',
  sDecorDetailId: (id: string) => {
    return `/s-decor/detail/${id}`;
  },
  sDecorType: '/s-decor/type',

  /**
   * S Home
   */
  sHomeGroup: '/s-home',
  sHome: '/s-home',
  sHomeMeta: '/s-home-meta',
  sHomeCreate: '/s-home/create',
  sHomeUpdate: '/s-home/edit/:id',
  sHomeUpdateId: (id: string) => {
    return `/s-home/edit/${id}`;
  },
  sHomeDetail: '/s-home/detail/:id',
  sHomeDetailId: (id: string) => {
    return `/s-home/detail/${id}`;
  },
  sHomeType: '/s-home/type',

  /**
   * S Funi
   */
  sFuniGroup: '/s-funi',
  sFuni: '/s-funi',
  sFuniMeta: '/s-funi-meta',
  sFuniCreate: '/s-funi/create',
  sFuniUpdate: '/s-funi/edit/:id',
  sFuniUpdateId: (id: string) => {
    return `/s-funi/edit/${id}`;
  },
  sFuniDetail: '/s-funi/detail/:id',
  sFuniDetailId: (id: string) => {
    return `/s-funi/detail/${id}`;
  },
  sFuniType: '/s-funi/type',

  /**
   * S Garden
   */
  sGardenGroup: '/s-garden',
  sGarden: '/s-garden',
  sGardenMeta: '/s-garden-meta',
  sGardenCreate: '/s-garden/create',
  sGardenUpdate: '/s-garden/edit/:id',
  sGardenUpdateId: (id: string) => {
    return `/s-garden/edit/${id}`;
  },
  sGardenDetail: '/s-garden/detail/:id',
  sGardenDetailId: (id: string) => {
    return `/s-garden/detail/${id}`;
  },
  sGardenType: '/s-garden/type',

  blogGroup: '/blog',
  blog: '/blog',
  blogCreate: '/blog/create',
  blogUpdate: '/blog/edit/:id',
  blogUpdateId: (id: string) => {
    return `/blog/edit/${id}`;
  },
  blogDetail: '/blog/detail/:id',
  blogDetailId: (id: string) => {
    return `/blog/detail/${id}`;
  },
  blogType: '/blog/type',

  adviseGroup: '/tu-van',
  advise: '/tu-van',
  adviseCreate: '/tu-van/create',
  adviseUpdate: '/tu-van/edit/:id',
  adviseUpdateId: (id: string) => {
    return `/tu-van/edit/${id}`;
  },
  adviseDetail: '/tu-van/detail/:id',
  adviseDetailId: (id: string) => {
    return `/tu-van/detail/${id}`;
  },
  adviseType: '/tu-van/type',

  settingGroup: '/cai-dat',
  settingContact: '/lien-he',
  settingMeta: '/meta',

  banner: '/banner'
};
