import { sHomeMetaApi } from 'configs/api/shome-meta';
import { toast } from 'react-toastify';

export function useSHomeMeta() {
  const editSHomeMeta = async (_values) => {
    try {
      const promise = sHomeMetaApi.update({ ..._values });
      const loading = toast.loading('Pending...');

      return await promise
        .then(async (res) => {
          toast.update(loading, { render: 'Success', type: 'success', autoClose: 5000, isLoading: false });
          return {
            success: true
          };
        })
        .catch(async (err) => {
          toast.update(loading, { render: 'Error', type: 'error', autoClose: 5000, isLoading: false });
          throw err;
        });
    } catch (err) {
      //
    }
  };

  const getSHomeMeta = async () => {
    try {
      const promise = sHomeMetaApi.get();
      return await promise
        .then(async (res) => {
          return res.data;
        })
        .catch(async (err) => {
          throw err;
        });
    } catch (err) {
      //
    }
  };

  return {
    getSHomeMeta,
    editSHomeMeta
  };
}
