import { AppRoute } from 'configs/router/config.router';
import { useAuthentication } from 'hooks/useAuthentication';
import React, { memo, useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';

const PrivateRoute = (props: any) => {
  const [loged, setLoged] = useState(true);

  const { validateToken } = useAuthentication();

  const _validateToken = async () => {
    await validateToken().then((res) => {
      if (!res) {
        setLoged(false);
      } else {
        setLoged(true);
      }
    });
  };

  useEffect(() => {
    _validateToken();
    // eslint-disable-next-line
  }, []);

  return <>{loged ? <Route {...props} /> : <Redirect to={AppRoute.login} />}</>;
};

export default memo(PrivateRoute);
