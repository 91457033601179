import { sFuniTypeApi } from 'configs/api/sfuni-type';
import { useDispatch } from 'react-redux';
import { setListProjectType } from 'store/actions/sFuniAction';
import { toast } from 'react-toastify';

export function useProjectType() {
  const disPatch = useDispatch();

  const createProject = async (values) => {
    try {
      const promise = sFuniTypeApi.create(values);
      const loading = toast.loading('Pending...');

      return await promise
        .then(async (res) => {
          toast.update(loading, { render: 'Success', type: 'success', autoClose: 5000, isLoading: false });
          return {
            success: true
          };
        })
        .catch(async (err) => {
          toast.update(loading, { render: 'Error', type: 'error', autoClose: 5000, isLoading: false });
          throw err;
        });
    } catch (err) {
      //
    }
  };

  const editProject = async (values) => {
    try {
      const promise = sFuniTypeApi.update(values);
      const loading = toast.loading('Pending...');

      return await promise
        .then(async (res) => {
          toast.update(loading, { render: 'Success', type: 'success', autoClose: 5000, isLoading: false });
          return {
            success: true
          };
        })
        .catch(async (err) => {
          toast.update(loading, { render: 'Error', type: 'error', autoClose: 5000, isLoading: false });
          throw err;
        });
    } catch (err) {
      //
    }
  };

  const deleteProject = async (id) => {
    try {
      const promise = sFuniTypeApi.delete(id);
      const loading = toast.loading('Pending...');

      return await promise
        .then(async (res) => {
          toast.update(loading, { render: 'Success', type: 'success', autoClose: 5000, isLoading: false });
          return {
            success: true
          };
        })
        .catch(async (err) => {
          toast.update(loading, { render: 'Error', type: 'error', autoClose: 5000, isLoading: false });
          throw err;
        });
    } catch (err) {
      //
    }
  };

  const getDataList = async () => {
    try {
      const promise = sFuniTypeApi.getList();
      return await promise
        .then(async (res) => {
          disPatch(setListProjectType(res.data));
          return true;
        })
        .catch(async (err) => {
          throw err;
        });
    } catch (err) {
      //
    }
  };

  return {
    getDataList,
    deleteProject,
    createProject,
    editProject
  };
}
