import React, { memo } from "react";
import ListProjectContainer from "modules/sdecor/list";

type Props = {
  //
};

const ListProject: React.FC<Props> = () => {
  return (
    <>
      <ListProjectContainer />
    </>
  );
};

export default memo(ListProject);
