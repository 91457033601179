import React, { memo } from 'react';
import { Helmet } from 'react-helmet';
import SettingContainer from 'modules/setting/contact';

type Props = {
  //
};

const Setting: React.FC<Props> = () => {
  return (
    <>
      <Helmet>
        <title>Setting Page</title>
      </Helmet>
      <SettingContainer />
    </>
  );
};

export default memo(Setting);
