import { headersAuthToken } from '../../utils/auth';
import { instance } from './../../apiBase/instance';

export const sGardenMetaApi = {
  async update(data) {
    return instance.put(`sgarden-meta`, data, await headersAuthToken());
  },

  async get() {
    return instance.get(`sgarden-meta/admin`, await headersAuthToken());
  }
};
