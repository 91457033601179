import React, { memo } from "react";
import TypeProjectContainer from "modules/sdecor/type";

type Props = {
  //
};

const TypeProject: React.FC<Props> = () => {
  return (
    <>
      <TypeProjectContainer />
    </>
  );
};

export default memo(TypeProject);
