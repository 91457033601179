import Detail from 'modules/sgarden/view';
import React, { memo } from 'react';

type Props = {
  //
};

const DetailProject: React.FC<Props> = () => {
  return (
    <>
      <Detail />
    </>
  );
};

export default memo(DetailProject);
