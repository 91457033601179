import { headersAuthToken } from '../../utils/auth';
import { instance } from './../../apiBase/instance';

export const sHomeMetaApi = {
  async update(data) {
    return instance.put(`shome-meta`, data, await headersAuthToken());
  },

  async get() {
    return instance.get(`shome-meta/admin`, await headersAuthToken());
  }
};
