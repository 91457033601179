// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_content__W9uie {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  background: res;
  overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./src/modules/sfuni/list/styles.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,4BAA4B;EAC5B,qBAAqB;EACrB,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".content {\n  display: -webkit-box;\n  -webkit-box-orient: vertical;\n  -webkit-line-clamp: 2;\n  background: res;\n  overflow: hidden;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `styles_content__W9uie`
};
export default ___CSS_LOADER_EXPORT___;
