import React, { memo } from 'react';
import TypeProjectContainer from 'modules/shome/type';

type Props = {
  //
};

const TypeProject: React.FC<Props> = () => {
  return (
    <>
      <TypeProjectContainer />
    </>
  );
};

export default memo(TypeProject);
