import React, { memo, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Input, Row } from 'antd';
import Title from 'components/Title';
import { useMeta } from 'hooks/useMeta';

type Props = {
  //
};

const SettingContainer: React.FC<Props> = () => {
  const [form] = Form.useForm();
  const { editSetting, getSetting } = useMeta();
  const [id, setId] = useState('');

  const onFinish = () => {
    const fields = form.getFieldsValue();

    editSetting(id, fields).then((res) => {
      _getSetting();
    });
  };

  const _getSetting = async () => {
    await getSetting().then((res) => {
      form.setFieldsValue({
        ...res?.data[0]
      });
      setId(res?.data[0]?._id);
    });
  };

  useEffect(() => {
    _getSetting();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Form name="normal_reviews" layout="vertical" form={form} onFinish={onFinish}>
        <Row gutter={24}>
          <Col span={24}>
            <Title level={4}>Setting Meta</Title>
          </Col>

          <Col sm={24} xs={24} style={{ marginBottom: 10 }}>
            <Card bordered={true} className="overflow-auto" style={{ marginBottom: 10 }}>
              <Form.Item name="description" label="Mô tả (Home page)" rules={[{ required: true, message: 'Vui lòng không được bỏ trống' }]}>
                <Input placeholder="Vui lòng nhập" />
              </Form.Item>
            </Card>
          </Col>
          <Col sm={24} xs={24} style={{ marginBottom: 20 }}>
            {id && (
              <Card bordered={true} className="overflow-auto" style={{ marginBottom: 20 }}>
                <Button type="primary" htmlType="submit">
                  Update
                </Button>
              </Card>
            )}
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default memo(SettingContainer);
