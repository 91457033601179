import React from 'react';
import { Layout } from 'antd';
import { Route, Switch, Redirect } from 'react-router-dom';
import styles from './styles.module.css';
import Sider from 'components/Sider';
import { useSelector } from 'react-redux';
import Header from 'components/Header';
import HomeIntro from 'views/home/intro';
import HomeOperationalResults from 'views/home/operational-results';
import HomeDesignProcess from 'views/home/design-process';
import HomeIntroFactory from 'views/home/intro-factory';
import HomeTrendDesign from 'views/home/trend-design';

import AboutService from 'views/about/service';
import AboutValue from 'views/about/value';
import AboutVision from 'views/about/vision';
import AboutTeam from 'views/about/team';

import SDecorType from 'views/sdecor/type';
import SDecorMeta from 'views/sdecor/meta';
import CreateSDecor from 'views/sdecor/create';
import SDecorDetail from 'views/sdecor/view';
import ListSDecor from 'views/sdecor/list';
import EditSDecor from 'views/sdecor/update';

import SHomeType from 'views/shome/type';
import SHomeMeta from 'views/shome/meta';
import CreateSHome from 'views/shome/create';
import SHomeDetail from 'views/shome/view';
import ListSHome from 'views/shome/list';
import EditSHome from 'views/shome/update';

import SFuniType from 'views/sfuni/type';
import SFuniMeta from 'views/sfuni/meta';
import CreateSFuni from 'views/sfuni/create';
import SFuniDetail from 'views/sfuni/view';
import ListSFuni from 'views/sfuni/list';
import EditSFuni from 'views/sfuni/update';

import SGardenType from 'views/sgarden/type';
import SGardenMeta from 'views/sgarden/meta';
import CreateSGarden from 'views/sgarden/create';
import SGardenDetail from 'views/sgarden/view';
import ListSGarden from 'views/sgarden/list';
import EditSGarden from 'views/sgarden/update';

import BlogType from 'views/blog/type';
import CreateBlog from 'views/blog/create';
import BlogDetail from 'views/blog/view';
import ListBlog from 'views/blog/list';
import EditBlog from 'views/blog/update';

import { AppRoute } from 'configs/router/config.router';
import PrivateRoute from './private-route';
import { RootState } from 'store/types';
import RouteLogin from './route-login';
import Login from 'views/login';
import Banner from 'views/banner';
import SettingContact from 'views/setting/contact';
import SettingMeta from 'views/setting/meta';
import Quote from 'views/quote';

import CreateAdvise from 'views/advise/create';
import AdviseDetail from 'views/advise/view';
import ListAdvise from 'views/advise/list';
import EditAdvise from 'views/advise/update';
import AdviseType from 'views/advise/type';

const { Content } = Layout;

const Routers = () => {
  const { sidebarCollapse } = useSelector((state: RootState) => state?.global);

  return (
    <>
      <Switch>
        <RouteLogin path={AppRoute.login}>
          <Login />
        </RouteLogin>
        <Route>
          <div className={styles.wrapper}>
            <Layout>
              <Sider />
              <Layout className="site-layout">
                <Header />

                <Content className={`${styles.content} ${sidebarCollapse ? styles.show : ''}`}>
                  <Switch>
                    {/* <PrivateRoute exact exact path={AppRoute.dashboard} component={dashboard} />*/}
                    <PrivateRoute exact path={AppRoute.homeIntro}>
                      <HomeIntro />
                    </PrivateRoute>
                    <PrivateRoute exact path={AppRoute.homeOperationalResults}>
                      <HomeOperationalResults />
                    </PrivateRoute>
                    <PrivateRoute exact path={AppRoute.homeDesignProcess}>
                      <HomeDesignProcess />
                    </PrivateRoute>
                    <PrivateRoute exact path={AppRoute.homeIntroFactory}>
                      <HomeIntroFactory />
                    </PrivateRoute>
                    <PrivateRoute exact path={AppRoute.homeTrendDesign}>
                      <HomeTrendDesign />
                    </PrivateRoute>

                    <PrivateRoute exact path={AppRoute.aboutService}>
                      <AboutService />
                    </PrivateRoute>
                    <PrivateRoute exact path={AppRoute.aboutValue}>
                      <AboutValue />
                    </PrivateRoute>
                    <PrivateRoute exact path={AppRoute.aboutVision}>
                      <AboutVision />
                    </PrivateRoute>
                    <PrivateRoute exact path={AppRoute.aboutTeam}>
                      <AboutTeam />
                    </PrivateRoute>

                    <PrivateRoute exact path={AppRoute.banner}>
                      <Banner />
                    </PrivateRoute>

                    <PrivateRoute exact path={AppRoute.settingContact}>
                      <SettingContact />
                    </PrivateRoute>
                    <PrivateRoute exact path={AppRoute.settingMeta}>
                      <SettingMeta />
                    </PrivateRoute>

                    <PrivateRoute exact path={AppRoute.sDecorType}>
                      <SDecorType />
                    </PrivateRoute>
                    <PrivateRoute exact path={AppRoute.sDecorMeta}>
                      <SDecorMeta />
                    </PrivateRoute>
                    <PrivateRoute exact path={AppRoute.sDecorDetail}>
                      <SDecorDetail />
                    </PrivateRoute>
                    <PrivateRoute exact path={AppRoute.sDecorCreate}>
                      <CreateSDecor />
                    </PrivateRoute>
                    <PrivateRoute exact path={AppRoute.sDecor}>
                      <ListSDecor />
                    </PrivateRoute>
                    <PrivateRoute exact path={AppRoute.sDecorUpdate}>
                      <EditSDecor />
                    </PrivateRoute>

                    <PrivateRoute exact path={AppRoute.sHomeType}>
                      <SHomeType />
                    </PrivateRoute>
                    <PrivateRoute exact path={AppRoute.sHomeMeta}>
                      <SHomeMeta />
                    </PrivateRoute>
                    <PrivateRoute exact path={AppRoute.sHomeDetail}>
                      <SHomeDetail />
                    </PrivateRoute>
                    <PrivateRoute exact path={AppRoute.sHomeCreate}>
                      <CreateSHome />
                    </PrivateRoute>
                    <PrivateRoute exact path={AppRoute.sHome}>
                      <ListSHome />
                    </PrivateRoute>
                    <PrivateRoute exact path={AppRoute.sHomeUpdate}>
                      <EditSHome />
                    </PrivateRoute>

                    <PrivateRoute exact path={AppRoute.sFuniType}>
                      <SFuniType />
                    </PrivateRoute>
                    <PrivateRoute exact path={AppRoute.sFuniMeta}>
                      <SFuniMeta />
                    </PrivateRoute>
                    <PrivateRoute exact path={AppRoute.sFuniDetail}>
                      <SFuniDetail />
                    </PrivateRoute>
                    <PrivateRoute exact path={AppRoute.sFuniCreate}>
                      <CreateSFuni />
                    </PrivateRoute>
                    <PrivateRoute exact path={AppRoute.sFuni}>
                      <ListSFuni />
                    </PrivateRoute>
                    <PrivateRoute exact path={AppRoute.sFuniUpdate}>
                      <EditSFuni />
                    </PrivateRoute>

                    <PrivateRoute exact path={AppRoute.sGardenType}>
                      <SGardenType />
                    </PrivateRoute>
                    <PrivateRoute exact path={AppRoute.sGardenMeta}>
                      <SGardenMeta />
                    </PrivateRoute>
                    <PrivateRoute exact path={AppRoute.sGardenDetail}>
                      <SGardenDetail />
                    </PrivateRoute>
                    <PrivateRoute exact path={AppRoute.sGardenCreate}>
                      <CreateSGarden />
                    </PrivateRoute>
                    <PrivateRoute exact path={AppRoute.sGarden}>
                      <ListSGarden />
                    </PrivateRoute>
                    <PrivateRoute exact path={AppRoute.sGardenUpdate}>
                      <EditSGarden />
                    </PrivateRoute>

                    <PrivateRoute exact path={AppRoute.blogType}>
                      <BlogType />
                    </PrivateRoute>
                    <PrivateRoute exact path={AppRoute.blogDetail}>
                      <BlogDetail />
                    </PrivateRoute>
                    <PrivateRoute exact path={AppRoute.blogCreate}>
                      <CreateBlog />
                    </PrivateRoute>
                    <PrivateRoute exact path={AppRoute.blog}>
                      <ListBlog />
                    </PrivateRoute>
                    <PrivateRoute exact path={AppRoute.blogUpdate}>
                      <EditBlog />
                    </PrivateRoute>

                    <PrivateRoute exact path={AppRoute.quote}>
                      <Quote />
                    </PrivateRoute>

                    <PrivateRoute exact path={AppRoute.adviseType}>
                      <AdviseType />
                    </PrivateRoute>
                    <PrivateRoute exact path={AppRoute.adviseDetail}>
                      <AdviseDetail />
                    </PrivateRoute>
                    <PrivateRoute exact path={AppRoute.adviseCreate}>
                      <CreateAdvise />
                    </PrivateRoute>
                    <PrivateRoute exact path={AppRoute.advise}>
                      <ListAdvise />
                    </PrivateRoute>
                    <PrivateRoute exact path={AppRoute.adviseUpdate}>
                      <EditAdvise />
                    </PrivateRoute>

                    <PrivateRoute path={AppRoute.basic}>
                      <Redirect to={AppRoute.homeIntro} />
                    </PrivateRoute>
                    <Redirect path="*" to={AppRoute.login} />
                  </Switch>
                </Content>
              </Layout>
            </Layout>
          </div>
        </Route>
      </Switch>
    </>
  );
};

export default Routers;
