import React, { memo, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Input, Row } from 'antd';
import Title from 'components/Title';
import { useSHomeMeta } from 'hooks/useSHomeMeta';
import RickTextEditorForm from 'components/RickTextEditorForm';
import BraftEditor, { ControlType } from 'braft-editor';

type Props = {
  //
};

const TextArea = Input.TextArea;

const controls: ControlType[] = [
  'redo',
  'undo',
  'separator',
  'emoji',
  'font-family',
  'font-size',
  'fullscreen',
  'headings',
  'letter-spacing',
  'line-height',
  'separator',
  'text-color',
  'bold',
  'italic',
  'strike-through',
  'separator',
  'superscript',
  'subscript',
  'remove-styles',
  'separator',
  'text-indent',
  'underline',
  'text-align',
  'separator',
  'list-ol',
  'list-ul',
  'table',
  'blockquote',
  'separator',
  'link',
  'hr',
  'clear'
];

const SHomeMetaContainer: React.FC<Props> = () => {
  const [form] = Form.useForm();
  const [data, setData] = useState<any>({});
  const { editSHomeMeta, getSHomeMeta } = useSHomeMeta();

  const onFinish = async () => {
    const fields = form.getFieldsValue();

    const _description = await fields.description.toHTML();

    editSHomeMeta({ ...fields, description: _description }).then((res) => {
      _getSHomeMeta();
    });
  };

  const _getSHomeMeta = async () => {
    await getSHomeMeta().then((res) => {
      setData(res.data[0]);
    });
  };

  useEffect(() => {
    _getSHomeMeta();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {Object.keys(data).length > 0 && (
        <Form
          name="normal_reviews"
          layout="vertical"
          form={form}
          onFinish={onFinish}
          initialValues={{
            ...data,
            description: BraftEditor.createEditorState(data.description)
          }}
        >
          <Row gutter={24}>
            <Col span={24}>
              <Title level={4}>Thông tin thêm</Title>
            </Col>

            <Col xs={24} style={{ marginBottom: 10 }}>
              <Card bordered={true} className="overflow-auto" style={{ marginBottom: 20 }}>
                {/* <Form.Item name={'description'} label="Mô tả" rules={[{ required: true, message: 'Vui lòng không được bỏ trống' }]}>
                <TextArea rows={4} placeholder="Mô tả" />
              </Form.Item> */}
                <Form.Item
                  label={'Mô tả'}
                  name="description"
                  validateTrigger={'onBlur'}
                  validateFirst
                  rules={[
                    {
                      required: true,
                      validator: (rule, value) => {
                        if (value.isEmpty()) {
                          return Promise.reject('Không được bỏ trống');
                        } else {
                          return Promise.resolve();
                        }
                      }
                    }
                  ]}
                >
                  <RickTextEditorForm
                    value={form.getFieldValue('description')}
                    controls={controls}
                    extendControls={[]}
                    handleChangeText={(val) => {
                      form.setFieldsValue({
                        description: val
                      });
                    }}
                  />
                </Form.Item>
              </Card>
            </Col>
            <Col xs={24} style={{ marginBottom: 10 }}>
              <Card bordered={true} className="overflow-auto" style={{ marginBottom: 20 }}>
                <Button type="primary" htmlType="submit">
                  Update
                </Button>
              </Card>
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
};

export default memo(SHomeMetaContainer);
