import SuspenseWithChunkError from 'components/common/SuspenseWithChunkError';
import styles from './styles.module.css';
import Layout from 'views/Layout';
import store from 'store/store';
import { Provider } from 'react-redux';
import NotFound from 'components/NotFound';

function App() {
  return (
    <Provider store={store}>
      <div className="App">
        <SuspenseWithChunkError fallback={<NotFound />}>
          <div className={styles.wrapper}>
            <Layout />
          </div>
        </SuspenseWithChunkError>
      </div>
    </Provider>
  );
}

export default App;
