import { headersAuthToken } from '../../utils/auth';
import { instance } from './../../apiBase/instance';

export const sDecorTypeApi = {
  async create(data) {
    return instance.post(`sdecor-type`, data, await headersAuthToken());
  },

  async update(data) {
    return instance.put(`sdecor-type`, data, await headersAuthToken());
  },

  async delete(id) {
    return instance.delete(`sdecor-type?id=${id}`, await headersAuthToken());
  },

  async getList() {
    return instance.get(`sdecor-type/admin`, await headersAuthToken());
  }
};
