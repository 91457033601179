import React, { memo, useEffect, useState } from 'react';
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Modal, Space, Switch, Table, Tooltip } from 'antd';
import styles from './styles.module.css';
import Title from 'components/Title';
import { useSGarden } from 'hooks/useSGarden';
import { useHistory, useLocation } from 'react-router-dom';
import { AppRoute } from 'configs/router/config.router';
import ImageAnt from 'components/ImageAnt';

type Props = {
  //
};

const { confirm } = Modal;

const ListProjectContainer: React.FC<Props> = () => {
  const history = useHistory();
  const { getList, editProject, deleteProject } = useSGarden();
  const [data, setData] = useState<any>();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const page = searchParams.get('page');

  const showConfirmDelete = (id: string) => {
    confirm({
      icon: <ExclamationCircleOutlined />,
      content: <Button>Are you sure?</Button>,
      onOk() {
        deleteProject(id).then((res) => {
          _getList();
        });
      },
      onCancel() {}
    });
  };

  const _getList = async () => {
    await getList(page ?? 1).then((res) => {
      setData({ ...(res?.data || {}) });
    });
  };

  const onUpdateStatusProject = async (id, active) => {
    await editProject({
      id: id,
      active: !active
    }).then((res) => {
      _getList();
    });
  };

  useEffect(() => {
    _getList();
    // eslint-disable-next-line
  }, [page]);

  const columns = [
    {
      title: 'Tên',
      dataIndex: 'id',
      width: '25%',
      render: (cell, row, index) => {
        return <p>{row?.name ?? ''}</p>;
      }
    },
    {
      title: 'Nội dung',
      dataIndex: 'id',
      width: '20%',
      render: (cell, row, index) => {
        return <div className={styles.content} dangerouslySetInnerHTML={{ __html: row?.shortDescription ?? '' }} />;
      }
    },
    {
      title: 'Ảnh đại diện',
      dataIndex: 'id',
      width: '20%',
      render: (cell, row, index) => {
        return row?.thumbnail ? (
          <ImageAnt
            id={row?.thumbnail ?? ''}
            preview={true}
            options={{
              width: 100
            }}
          />
        ) : (
          <></>
        );
      }
    },
    {
      title: 'Trạng thái',
      dataIndex: 'id',
      width: '25%',
      render: (cell, row) => {
        return (
          <div className="flex-align-items">
            <Switch
              checked={row?.active}
              onChange={(checked, event) => {
                onUpdateStatusProject(row._id, row.active);
              }}
            />
            <span style={{ marginLeft: 10 }}>{row?.active ? 'Active' : 'Inactive'}</span>
          </div>
        );
      }
    },
    {
      title: 'Hành động',
      dataIndex: 'id',
      width: '5%',
      render: (cell, row, index) => {
        return (
          <Space>
            <Tooltip title={'Chi tiết'}>
              <Button
                onClick={() => {
                  history.push(AppRoute.sGardenDetailId(row?._id));
                }}
                icon={<EyeOutlined />}
              />
            </Tooltip>
            <Tooltip title={'Sửa'}>
              <div
                onClick={() => {
                  history.push(AppRoute.sGardenUpdateId(row?._id));
                }}
              >
                <Button icon={<EditOutlined />} />
              </div>
            </Tooltip>
            <Tooltip title={'Delete'}>
              <Button
                onClick={() => {
                  showConfirmDelete(row._id);
                }}
                icon={<DeleteOutlined />}
              />
            </Tooltip>
          </Space>
        );
      }
    }
  ];

  return (
    <>
      <Title level={4}>Danh sách dự án</Title>

      {data?.data && (
        <Table
          // @ts-ignore
          dataSource={data?.data || []}
          columns={columns}
          style={{ margin: 0 }}
          className="table_list"
          pagination={{
            position: ['bottomCenter'],
            current: Number(page ? page : 1),
            total: data?.totalItem ? data?.totalItem : 1,
            pageSize: data?.itemPerpage ? data?.itemPerpage : 10,
            onChange: (skip: number) => {
              history.push({
                pathname: AppRoute.sGarden,
                search: `?page=${skip}`
              });
            },
            showSizeChanger: false
          }}
        />
      )}
    </>
  );
};

export default memo(ListProjectContainer);
