import { Card, Col, Row } from 'antd';
import Title from 'components/Title';
import React, { memo, useEffect } from 'react';
import List from './components/list';
import Create from './components/create';
import { useProjectType } from 'hooks/useSGardenType';

type Props = {
  //
};

const TypeProjectContainer: React.FC<Props> = () => {
  const { getDataList } = useProjectType();

  useEffect(() => {
    getDataList();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Row gutter={24}>
        <Col span={24}>
          <Title level={4}>Thêm loại dự án s garden</Title>
        </Col>

        <Col sm={12} xs={24} style={{ marginBottom: 20 }}>
          <Card bordered={true} className="overflow-auto">
            <List />
          </Card>
        </Col>
        <Col sm={12} xs={24} style={{ marginBottom: 20 }}>
          <Card bordered={true}>
            <Create />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default memo(TypeProjectContainer);
