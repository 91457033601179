import {
  ProjectAction,
  ProjectState,
  SET_IS_EDIT_SGARDEN_PROJECT_TYPE,
  SET_LIST_SGARDEN_PROJECT_TYPE,
  SET_SGARDEN_PROJECT_TYPE_EDIT
} from '../types/sGardenTypes';

const initialState: ProjectState = {
  projectTypeList: null,
  isEditProjectType: false,
  projectTypeEdit: null
};

const ProjectReducer = (state = initialState, action: ProjectAction): ProjectState => {
  switch (action.type) {
    case SET_LIST_SGARDEN_PROJECT_TYPE:
      return {
        ...state,
        projectTypeList: action?.payload
      };
    case SET_IS_EDIT_SGARDEN_PROJECT_TYPE:
      return {
        ...state,
        isEditProjectType: action?.payload
      };
    case SET_SGARDEN_PROJECT_TYPE_EDIT:
      return {
        ...state,
        projectTypeEdit: action?.payload
      };
    default:
      return state;
  }
};

export default ProjectReducer;
