import Update from 'modules/sgarden/update';
import React, { memo } from 'react';

type Props = {
  //
};

const UpdateProject: React.FC<Props> = () => {
  return (
    <>
      <Update />
    </>
  );
};

export default memo(UpdateProject);
