import React, { memo } from 'react';
import { Helmet } from 'react-helmet';
import MetaContainer from 'modules/sfuni/meta';

type Props = {
  //
};

const Meta: React.FC<Props> = () => {
  return (
    <>
      <Helmet>
        <title>Thông tin thêm</title>
      </Helmet>
      <MetaContainer />
    </>
  );
};

export default memo(Meta);
