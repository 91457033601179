import React, { memo } from 'react';
import { Helmet } from 'react-helmet';
import MetaContainer from 'modules/setting/meta';

type Props = {
  //
};

const Meta: React.FC<Props> = () => {
  return (
    <>
      <Helmet>
        <title>Meta Page</title>
      </Helmet>
      <MetaContainer />
    </>
  );
};

export default memo(Meta);
