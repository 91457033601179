import { headersAuthToken } from '../../utils/auth';
import { instance } from './../../apiBase/instance';

export const sGardenTypeApi = {
  async create(data) {
    return instance.post(`sgarden-type`, data, await headersAuthToken());
  },

  async update(data) {
    return instance.put(`sgarden-type`, data, await headersAuthToken());
  },

  async delete(id) {
    return instance.delete(`sgarden-type?id=${id}`, await headersAuthToken());
  },

  async getList() {
    return instance.get(`sgarden-type/admin`, await headersAuthToken());
  }
};
