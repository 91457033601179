import { headersAuthToken } from '../../utils/auth';
import { instance } from './../../apiBase/instance';

export const sFuniMetaApi = {
  async update(data) {
    return instance.put(`sfuni-meta`, data, await headersAuthToken());
  },

  async get() {
    return instance.get(`sfuni-meta/admin`, await headersAuthToken());
  }
};
