import React, { memo, useEffect, useState } from 'react';
import { Layout, Menu, MenuProps } from 'antd';
import { AppRoute, useRouteTranslation } from 'configs/router/config.router';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/types';
import { setCollapseSidebar } from 'store/actions/globalAction';
import styles from './styles.module.css';

const { Sider } = Layout;

const SiderCpn = () => {
  const dispatch = useDispatch();
  const { sidebarCollapse } = useSelector((state: RootState) => state?.global);
  const history = useHistory();
  const { pathname } = useLocation();
  const [keySelected, setKeySelected] = useState<string>('');

  const handleSelectedKey = () => {
    let routeExists = Object.keys(AppRoute).find((item) => AppRoute[item] === pathname);

    if (routeExists) {
      setKeySelected(AppRoute[routeExists]);
    } else {
      let keySlice = pathname;
      do {
        keySlice = keySlice.slice(0, keySlice.lastIndexOf('/'));

        // eslint-disable-next-line
        routeExists = Object.keys(AppRoute).find((item) => AppRoute[item] === keySlice);
      } while (!routeExists);
      setKeySelected(keySlice);
    }
  };

  const onClick: MenuProps['onClick'] = (e) => {
    history.push(e.key);
  };

  useEffect(() => {
    handleSelectedKey();

    // eslint-disable-next-line
  }, [pathname]);

  return (
    <>
      <div className={styles.wrapper}>
        <Sider
          collapsible
          collapsed={sidebarCollapse}
          className={`${styles.sider} sider-custom`}
          onCollapse={(value) => {
            dispatch(setCollapseSidebar(value));
          }}
        >
          <div className={`${styles.logo}`}>
            <img className={styles.imgLogo} src={require(`assets/images/logos/logo-no-text.svg`).default} alt="" />
          </div>
          <Menu
            onClick={onClick}
            className={`${styles.menu} no-sb`}
            mode="inline"
            defaultOpenKeys={[
              pathname.slice(pathname.indexOf('/') + 1, pathname.lastIndexOf('/') === 0 ? pathname.length : pathname.indexOf('/', pathname.indexOf('/') + 1))
            ]}
            selectedKeys={[keySelected]}
            items={useRouteTranslation()}
          />
        </Sider>
      </div>
    </>
  );
};

export default memo(SiderCpn);
