import { projectApi } from 'configs/api/sgarden';
import { toast } from 'react-toastify';

export function useSGarden() {
  const createProject = async (values) => {
    try {
      const promise = projectApi.create(values);
      const loading = toast.loading('Pending...');

      return await promise
        .then(async (res) => {
          toast.update(loading, { render: 'Success', type: 'success', autoClose: 5000, isLoading: false });
          return {
            id: res.data,
            success: true
          };
        })
        .catch(async (err) => {
          toast.update(loading, { render: 'Error', type: 'error', autoClose: 5000, isLoading: false });
          throw err;
        });
    } catch (err) {
      //
    }
  };

  const viewProject = async (id) => {
    try {
      const promise = projectApi.getDetail(id);

      return await promise
        .then(async (res) => {
          return {
            data: res.data,
            success: true
          };
        })
        .catch(async (err) => {
          throw err;
        });
    } catch (err) {
      //
    }
  };

  const getList = async (page) => {
    try {
      const promise = projectApi.getList(page);

      return await promise
        .then(async (res) => {
          return {
            data: res.data,
            success: true
          };
        })
        .catch(async (err) => {
          throw err;
        });
    } catch (err) {
      //
    }
  };

  const editProject = async (values) => {
    try {
      const promise = projectApi.update(values);
      const loading = toast.loading('Pending...');

      return await promise
        .then(async (res) => {
          toast.update(loading, { render: 'Success', type: 'success', autoClose: 5000, isLoading: false });
          return {
            success: true
          };
        })
        .catch(async (err) => {
          toast.update(loading, { render: 'Error', type: 'error', autoClose: 5000, isLoading: false });
          throw err;
        });
    } catch (err) {
      //
    }
  };

  const deleteProject = async (id) => {
    try {
      const promise = projectApi.delete(id);
      const loading = toast.loading('Pending...');

      return await promise
        .then(async (res) => {
          toast.update(loading, { render: 'Success', type: 'success', autoClose: 5000, isLoading: false });
          return {
            success: true
          };
        })
        .catch(async (err) => {
          toast.update(loading, { render: 'Error', type: 'error', autoClose: 5000, isLoading: false });
          throw err;
        });
    } catch (err) {
      //
    }
  };

  return {
    createProject,
    viewProject,
    getList,
    editProject,
    deleteProject
  };
}
