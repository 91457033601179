import React, { memo, useEffect, useState } from 'react';
import Title from 'components/Title';
import { Button, Card, Col, Divider, Row, Tag } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { useSDecor } from 'hooks/useSDecor';
import { CheckCircleOutlined, CloseCircleOutlined, EditOutlined } from '@ant-design/icons';
import ImageAnt from 'components/ImageAnt';
import { AppRoute } from 'configs/router/config.router';

type Props = {
  //
};

export const generateStatus = (status) => {
  if (status) {
    return <Tag style={{ color: 'blue', border: '1px solid blue', margin: '2px' }}>{'Active'}</Tag>;
  } else {
    return <Tag style={{ color: 'red', border: '1px solid red', margin: '2px' }}>{'InActive'}</Tag>;
  }
};

const DetailProjectContainer: React.FC<Props> = () => {
  const params: any = useParams();
  const [data, setData] = useState(null);
  const { viewProject, editProject } = useSDecor();
  const history = useHistory();
  const [html, setHtml] = useState<any>();

  const onUpdateStatusProjectType = async (id, active) => {
    await editProject({
      id: id,
      active: !active
    }).then((res) => {
      _viewProject();
    });
  };

  const _viewProject = async () => {
    if (params?.id) {
      await viewProject(params?.id ?? '')
        .then((res) => {
          setData(res?.data);
          setHtml(res?.data?.content);
        })
        .catch(() => {});
    }
  };

  useEffect(() => {
    _viewProject();
    // eslint-disable-next-line
  }, [params.id]);

  return (
    <>
      {data && (
        <Row gutter={24} className="disable-tailwind">
          <Col span={24} className="flex justify-between mb-5">
            <div className="flex items-end">{generateStatus(data?.active)}</div>
            <div>
              <Button
                icon={<EditOutlined />}
                size={'large'}
                style={{
                  background: '#ffc107',
                  border: '1px solid #ffc107',
                  marginRight: 10
                }}
                onClick={() => {
                  history.push(AppRoute.sDecorUpdateId(data?._id));
                }}
              >
                Chỉnh sửa
              </Button>
              {!data?.active ? (
                <Button
                  onClick={() => onUpdateStatusProjectType(data?._id, data?.active)}
                  icon={<CheckCircleOutlined />}
                  size={'large'}
                  style={{
                    background: '#1100ff',
                    border: '1px solid #1100ff',
                    color: '#fff',
                    marginRight: 10
                  }}
                >
                  Active
                </Button>
              ) : (
                <Button
                  onClick={() => onUpdateStatusProjectType(data?._id, data?.active)}
                  icon={<CloseCircleOutlined />}
                  size={'large'}
                  style={{
                    background: '#ff0000',
                    border: '1px solid #ff0000',
                    color: '#fff',
                    marginRight: 10
                  }}
                >
                  Hidden
                </Button>
              )}
            </div>
          </Col>
          {data?._id && (
            <>
              <Col sm={12} xs={24} style={{ marginBottom: 20 }}>
                <Card className="mb-5 h-full">
                  <Row gutter={24} className="kar-row-item">
                    <Col span={24}>
                      <Title level={4}>{data?.name ?? ''}</Title>
                    </Col>
                  </Row>
                  <Divider />
                  <Row gutter={24} className="kar-row-item">
                    <Col span={24}>
                      <Title level={5}>Mô tả ngắn:</Title>
                    </Col>
                    <Col span={24}>
                      <span>{data?.shortDescription ?? ''}</span>
                    </Col>
                  </Row>
                  <Divider />
                  <Row gutter={24} className="kar-row-item">
                    <Col span={24}>
                      <Title level={5}>Loại bài viết:</Title>
                    </Col>
                    <Col span={24}>
                      <span>{data?.type?.name ?? ''}</span>
                    </Col>
                  </Row>
                </Card>
              </Col>

              <Col sm={12} xs={24} style={{ marginBottom: 20 }}>
                <Card className="mb-5 h-full">
                  <Row gutter={24} className="kar-row-item">
                    <Col span={24}>
                      <Title level={5}>Ảnh đại diện:</Title>
                    </Col>
                    <Col span={24}>
                      <ImageAnt preview={true} options={{ width: 250 }} id={data?.thumbnail} />
                    </Col>
                  </Row>
                </Card>
              </Col>

              <Col sm={24} xs={24} style={{ marginBottom: 20 }}>
                <Card className="mb-5 h-full all-revert">
                  <Row gutter={24} className="kar-row-item">
                    <Col span={24}>
                      <Title level={5}>Nội dung:</Title>
                    </Col>
                    <Col span={24}>{html ? <div dangerouslySetInnerHTML={{ __html: html ?? '' }}></div> : <Card loading={true} />}</Col>
                  </Row>
                </Card>
              </Col>
            </>
          )}
        </Row>
      )}
    </>
  );
};

export default memo(DetailProjectContainer);
