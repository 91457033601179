import { metaApi } from 'configs/api/meta';
import { toast } from 'react-toastify';

export function useMeta() {
  const editSetting = async (id, _values) => {
    try {
      const promise = metaApi.update({ id, ..._values });
      const loading = toast.loading('Pending...');

      return await promise
        .then(async (res) => {
          toast.update(loading, { render: 'Success', type: 'success', autoClose: 5000, isLoading: false });
          return {
            success: true
          };
        })
        .catch(async (err) => {
          toast.update(loading, { render: 'Error', type: 'error', autoClose: 5000, isLoading: false });
          throw err;
        });
    } catch (err) {
      //
    }
  };

  const getSetting = async () => {
    try {
      const promise = metaApi.get();
      return await promise
        .then(async (res) => {
          return res.data;
        })
        .catch(async (err) => {
          throw err;
        });
    } catch (err) {
      //
    }
  };

  return {
    getSetting,
    editSetting
  };
}
