import { headersAuthToken } from '../../utils/auth';
import { instance } from './../../apiBase/instance';

export const sDecorMetaApi = {
  async update(data) {
    return instance.put(`sdecor-meta`, data, await headersAuthToken());
  },

  async get() {
    return instance.get(`sdecor-meta/admin`, await headersAuthToken());
  }
};
