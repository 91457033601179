export const SET_LIST_SDECOR_PROJECT_TYPE = 'SET_LIST_SDECOR_PROJECT_TYPE';
export const SET_IS_EDIT_SDECOR_PROJECT_TYPE = 'SET_IS_EDIT_SDECOR_PROJECT_TYPE';
export const SET_SDECOR_PROJECT_TYPE_EDIT = 'SET_SDECOR_PROJECT_TYPE_EDIT';

export interface ProjectState {
  projectTypeList: any;
  isEditProjectType: boolean;
  projectTypeEdit: any;
}

interface setListProjectTypeAction {
  type: typeof SET_LIST_SDECOR_PROJECT_TYPE;
  payload: any;
}

interface setIsEditProjectTypeAction {
  type: typeof SET_IS_EDIT_SDECOR_PROJECT_TYPE;
  payload: any;
}

interface setProjectTypeEditAction {
  type: typeof SET_SDECOR_PROJECT_TYPE_EDIT;
  payload: any;
}

export type ProjectAction = setListProjectTypeAction | setIsEditProjectTypeAction | setProjectTypeEditAction;
