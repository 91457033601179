import { sDecorMetaApi } from 'configs/api/sdecor-meta';
import { toast } from 'react-toastify';

export function useSDecorMeta() {
  const editSDecorMeta = async (_values) => {
    try {
      const promise = sDecorMetaApi.update({ ..._values });
      const loading = toast.loading('Pending...');

      return await promise
        .then(async (res) => {
          toast.update(loading, { render: 'Success', type: 'success', autoClose: 5000, isLoading: false });
          return {
            success: true
          };
        })
        .catch(async (err) => {
          toast.update(loading, { render: 'Error', type: 'error', autoClose: 5000, isLoading: false });
          throw err;
        });
    } catch (err) {
      //
    }
  };

  const getSDecorMeta = async () => {
    try {
      const promise = sDecorMetaApi.get();
      return await promise
        .then(async (res) => {
          return res.data;
        })
        .catch(async (err) => {
          throw err;
        });
    } catch (err) {
      //
    }
  };

  return {
    getSDecorMeta,
    editSDecorMeta
  };
}
