import React, { memo, useEffect, useState } from 'react';
import Title from 'components/Title';
import { Card, Col, Row } from 'antd';
import Form from '../components/form';
import { useParams } from 'react-router-dom';
import { useSDecor } from 'hooks/useSDecor';

type Props = {
  //
};

const UpdateProjectContainer: React.FC<Props> = () => {
  const params: any = useParams();
  const [data, setData] = useState(null);
  const { viewProject } = useSDecor();

  const _viewProject = async () => {
    if (params.id) {
      await viewProject(params?.id ?? '').then(async (res) => {
        setData({ ...res.data });
      });
    }
  };

  useEffect(() => {
    _viewProject();
    // eslint-disable-next-line
  }, [params.id]);

  return (
    <>
      <Row gutter={24}>
        <Col span={24}>
          <Title level={4}>Chỉnh sửa</Title>
        </Col>

        <Col sm={24} xs={24} style={{ marginBottom: 20 }}>
          {data ? <Form isEdit={true} post={data} /> : <Card loading={true} />}
        </Col>
      </Row>
    </>
  );
};

export default memo(UpdateProjectContainer);
